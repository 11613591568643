import * as Auth from "./Auth";

import * as MonthPromotion from "./MonthPromotions";
import * as WeekPromotion from "./WeekPromotions";
import * as ArticleCategories from "./ArticleCategories";
import * as ArticleMultipliers from "./ArticleMultipliers";
import * as ArticleSearch from "./ArticleSearch";
import * as Customers from "./Customers";
import * as CustomerScore from "./CustomerScore";
import * as News from "./News";
import * as Markets from "./Markets";
import * as Faqs from "./Faqs";
import * as Documents from "./Documents";
import * as CardOverview from "./CardOverview";
import * as TrackingConsent from "./TrackingConsent";
import { AppState } from "./configureStore";

export const reducers = {
    authReducer: Auth.reducer,
    monthPromotionReducer: MonthPromotion.reducer,
    weekPromotionReducer: WeekPromotion.reducer,
    multiplierReducer: ArticleMultipliers.reducer,
    articleSearchReducer: ArticleSearch.reducer,
    articleCategoriesReducer: ArticleCategories.reducer,
    customerReducer: Customers.reducer,
    customerScoreReducer: CustomerScore.reducer,
    newsReducer: News.reducer,
    marketsReducer: Markets.reducer,
    faqsReducer: Faqs.reducer,
    documentsReducer: Documents.reducer,
    cardOverviewReducer: CardOverview.reducer,
    trackingConsentReducer: TrackingConsent.reducer,
};

export const actionCreators = {
    auth: Auth.actionCreators,
    monthPromotion: MonthPromotion.actionCreators,
    weekPromotion: WeekPromotion.actionCreators,
    articleMultipliers: ArticleMultipliers.actionCreators,
    articleSearch: ArticleSearch.actionCreators,
    articleCategories: ArticleCategories.actionCreators,
    customers: Customers.actionCreators,
    customerScore: CustomerScore.actionCreators,
    news: News.actionCreators,
    markets: Markets.actionCreators,
    faqs: Faqs.actionCreators,
    documents: Documents.actionCreators,
    cardOverview: CardOverview.actionCreators,
    trackingConsent: TrackingConsent.actionCreators,
};

export interface AppThunkAction<TAction, TDispatchResponse = void> {
    (dispatch: (action: TAction) => TDispatchResponse, getState: () => AppState): void;
}
