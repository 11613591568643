export interface BaseType {
    dontPersist?: boolean;
}

// All possible actions which can be done via the redux store
export enum AllReduxActions {
    // PROMOTIONS
    GET_MONTH_PROMOTIONS = "GET_MONTH_PROMOTIONS",
    GET_MONTH_PROMOTIONS_SUCCESS = "GET_MONTH_PROMOTIONS_SUCCESS",
    GET_MONTH_PROMOTIONS_FAIL = "GET_MONTH_PROMOTIONS_FAIL",
    CLEAR_PROMOTIONS_LOADING_STATE = "CLEAR_PROMOTIONS_LOADING_STATE",
    ACTIVATE_PROMOTIONS = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER",
    ACTIVATE_PROMOTIONS_SUCCESS = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER_SUCCESS",
    ACTIVATE_PROMOTIONS_FAIL = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER_FAIL",
    GET_WEEK_PROMOTIONS = "GET_WEEK_PROMOTIONS",
    GET_WEEK_PROMOTIONS_SUCCESS = "GET_WEEK_PROMOTIONS_SUCCESS",
    GET_WEEK_PROMOTIONS_FAIL = "GET_WEEK_PROMOTIONS_FAIL",
    CLEAR_ALL_PROMOTIONS = "CLEAR_ALL",
    VALIDATE_MULTIPLIER_COLLISIONS = "VALIDATE_MULTIPLIER_COLLISIONS",
    VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS = "VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS",
    VALIDATE_MULTIPLIER_COLLISIONS_FAIL = "VALIDATE_MULTIPLIER_COLLISIONS_FAIL",

    //ArticleCategories
    GET_ARTICLE_CATEGORIES = "tebonus/articles/GET_ARTICLE_CATEGORIES",
    GET_ARTICLE_CATEGORIES_SUCCESS = "tebonus/articles/GET_ARTICLE_CATEGORIES_SUCCESS",
    GET_ARTICLE_CATEGORIES_FAIL = "tebonus/articles/GET_ARTICLE_CATEGORIES_FAIL",

    //ArticleMultipliers
    SET_ARTICLE_MULTIPLIERS = "pekuma/articles/SET_MULTIPLIERS",
    SET_ARTICLE_MULTIPLIERS_SUCCESS = "pekuma/articles/SET_MULTIPLIERS_SUCCESS",
    SET_ARTICLE_MULTIPLIERS_FAIL = "pekuma/articles/SET_MULTIPLIERS_FAIL",
    GET_ARTICLE_MULTIPLIERS = "pekuma/articles/GET_MULTIPLIERS",
    GET_ARTICLE_MULTIPLIERS_SUCCESS = "pekuma/articles/GET_MULTIPLIERS_SUCCESS",
    GET_ARTICLE_MULTIPLIERS_FAIL = "pekuma/articles/GET_MULTIPLIERS_FAIL",
    GET_ARTICLE_MULTIPLIER_DETAILS = "pekuma/articles/GET_ARTICLE_MULTIPLIER_DETAILS",
    GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS = "pekuma/articles/GET_ARTICLE_MULTIPLIER_DETAILS_SUCCESS",
    GET_ARTICLE_MULTIPLIER_DETAILS_FAIL = "pekuma/articles/GET_ARTICLE_MULTIPLIER_DETAILS_FAIL",
    SAVE_MULTIPLIER_DETAILS = "pekuma/articles/SAVE_MULTIPLIER_DETAILS",
    VALIDATE_PROMOTION_COLLISIONS = "pekuma/articles/VALIDATE_MULTIPLIER_PROMOTIONS_COLLISIONS",
    VALIDATE_PROMOTION_COLLISIONS_SUCCESS = "pekuma/articles/VALIDATE_MULTIPLIER_PROMOTIONS_COLLISIONS_SUCCESS",
    VALIDATE_PROMOTION_COLLISIONS_FAIL = "pekuma/articles/VALIDATE_MULTIPLIER_PROMOTIONS_COLLISIONS_FAIL",
    CLEAR_MULTIPLIER_LOADING_STATE = "pekuma/articles/CLEAR_MULTIPLIER_LOADING_STATE",
    CLEAR_PROMOTION_COLLISIONS = "pekuma/articles/CLEAR_PROMOTION_COLLISIONS",
    CLEAR_ALL_MULTIPLIERS = "pekuma/articles/CLEAR_ALL",

    // Customer
    GET_CUSTOMER_INFO = "pekuma/customers/GET_INFO",
    GET_CUSTOMER_INFO_SUCCESS = "pekuma/customers/GET_INFO_SUCCESS",
    GET_CUSTOMER_INFO_FAIL = "pekuma/customers/GET_INFO_FAIL",
    UPDATE_ONBOARDING_SHOWN = "pekuma/customers/UPDATE_ONBOARDING_SHOWN",
    CLEAR_CUSTOMER_LOADING_STATE = "pekuma/customers/CLEAR_CUSTOMER_LOADING_STATE",

    SET_ANALYSE_OPT_IN = "pekuma/customers/SET_ANALYSE_OPT_IN",
    SET_ANALYSE_OPT_IN_SUCCESS = "pekuma/customers/SET_ANALYSE_OPT_IN_SUCCESS",
    SET_ANALYSE_OPT_IN_FAIL = "pekuma/customers/SET_ANALYSE_OPT_IN_FAIL",

    CLEAR_ALL_CUSTOMER = "pekuma/customers/CLEAR_ALL",
    SEND_CUSTOMER_CONFIRMATION_MAIL = "pekuma/customers/SEND_CUSTOMER_CONFIRMATION_MAIL",
    SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS = "pekuma/customers/SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS",
    SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL = "pekuma/customers/SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL",

    TERMINATE_TEBONUS_CUSTOMER = "pekuma/customers/TERMINATE_TEBONUS_CUSTOMER",
    TERMINATE_TEBONUS_CUSTOMER_SUCCESS = "pekuma/customers/TERMINATE_TEBONUS_CUSTOMER_SUCCESS",
    TERMINATE_TEBONUS_CUSTOMER_FAIL = "pekuma/customers/TERMINATE_TEBONUS_CUSTOMER_FAIL",

    // CustomerScore
    GET_CUSTOMER_SCORE = "pekuma/customers/GET_SCORE",
    GET_CUSTOMER_SCORE_SUCCESS = "pekuma/customers/GET_SCORE_SUCCESS",
    GET_CUSTOMER_SCORE_FAIL = "pekuma/customers/GET_SCORE_FAIL",
    GET_CUSTOMER_SCORETRANSACTIONS = "pekuma/customers/GET_SCORETRANSACTIONS",
    GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS = "pekuma/customers/GET_SCORETRANSACTIONS_SUCCESS",
    GET_CUSTOMER_SCORETRANSACTIONS_FAIL = "pekuma/customers/GET_SCORETRANSACTIONS_FAIL",
    CLEAR_CUSTOMER_SCORE_LOADING_STATE = "pekuma/customers/CLEAR_CUSTOMER_SCORE_LOADING_STATE",
    CLEAR_CUSTOMER_SCORE_TRANSACTIONS = "pekuma/customers/CLEAR_CUSTOMER_SCORE_TRANSACTIONS",
    CLEAR_ALL_CUSTOMER_SCORE = "pekuma/customers/CLEAR_ALL",

    // FAQs
    GET_FAQS = "Pekuma/faqs/GET_FAQ",
    GET_FAQS_SUCCESS = "Pekuma/faqs/GET_FAQ_SUCCESS",
    GET_FAQS_FAIL = "Pekuma/faqs/GET_FAQ_FAIL",
    CLEAR_FAQS_LOADING_STATE = "pekuma/faqs/CLEAR_FAQS_LOADING_STATE",

    // News
    GET_NEWS = "pekuma/news/GET_NEWS",
    GET_NEWS_SUCCESS = "pekuma/news/GET_NEWS_SUCCESS",
    GET_NEWS_FAIL = "pekuma/news/GET_NEWS_FAIL",
    CLEAR_NEWS_LOADING_STATE = "pekuma/news/CLEAR_NEWS_LOADING_STATE",

    // Documents
    GET_DOCUMENTS = "pekuma/documents/GET_DOCUMENTS",
    GET_DOCUMENTS_SUCCESS = "pekuma/documents/GET_DOCUMENTS_SUCCESS",
    GET_DOCUMENTS_FAIL = "pekuma/documents/GET_DOCUMENTS_FAIL",

    // CardOverviewCards
    GET_CARDOVERVIEW_CARDS = "pekuma/cardoverview/GET_CARDOVERVIEW_CARDS",
    GET_CARDOVERVIEW_CARDS_SUCCESS = "pekuma/cardoverview/GET_CARDOVERVIEW_CARDS_SUCCESS",
    GET_CARDOVERVIEW_CARDS_FAIL = "pekuma/cardoverview/GET_CARDOVERVIEW_CARDS_FAIL",
    CLEAR_ALL_CARDOVERVIEW_CARDS = "pekuma/cardoverview/CLEAR_ALL",
    SEND_CARD_LOCK = "pekuma/cardoverview/SEND_CARD_LOCK",
    SEND_CARD_LOCK_SUCCESS = "pekuma/cardoverview/SEND_CARD_LOCK_SUCCESS",
    SEND_CARD_LOCK_FAIL = "pekuma/cardoverview/SEND_CARD_LOCK_FAIL",

    // Authentication
    REDIRECT_TO_IDP_SERVER = "REDIRECT_TO_IDP_SERVER",
    REDIRECT_TO_IDP_SERVER_SUCCESS = "REDIRECT_TO_IDP_SERVER_SUCCESS",
    REDIRECT_TO_IDP_SERVER_FAIL = "REDIRECT_TO_IDP_SERVER_FAIL",
    USER_AUTHENTICATE_SUCCESS = "USER_AUTHENTICATE_SUCCESS",
    USER_AUTHENTICATE_FAIL = "USER_AUTHENTICATE_FAIL",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    LOGOUT_FAIL = "LOGOUT_FAIL",
    REFRESH_TOKEN = "REFRESH_TOKEN",
    REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL",
    RESET_AUTH_STATE = "RESET_AUTH_STATE",
    DESIRED_LANDING_URL = "DESIRED_LANDING_URL",

    // TrackingConsent
    SET_TRACKING_CONSENT = "SET_TRACKING_CONSENT",
    SET_TRACKING_CONSENT_SUCCESS = "SET_TRACKING_CONSENT_SUCCESS",
    SET_TRACKING_CONSENT_FAIL = "SET_TRACKING_CONSENT_FAIL",
}

export enum PromotionType {
    Month = 0,
    Week = 1,
}

export interface GetPromotionsResponse {
    promotions: Promotion[];
    lastUpdate: number;
    arePromotionsLocked: boolean;
    nextChangeDate: string;
}

export interface NewsSlide {
    news?: News;
    contentPanel?: NewsContentPanel;
}

export interface NewsContentPanel {
    link: string;
    title: string;
    subHeader: Element;
    image: string;
}

export type SearchProps = {
    id: string;
    name: string;
};

export interface LocationState {
    index: number;
    advantageAmount: number;
    isEditable: boolean;
    isInitial: boolean;
}

export interface MultiplierBaseProps {
    isInitial: boolean;
    isEditable: boolean;
    isLoading?: boolean;
}

export interface AnalyticsButtonPromptInfos {
    parameters: object;
}

export interface ReduxBaseType {
    isLoading: boolean;
    errorCode: number;
    lastUpdate?: number;
    performedAction?: AllReduxActions;
}

export interface CustomerBase extends BaseType {
    firstName: string;
    customerNumber: string;
    lastName: string;
    email: string;
    id: string;
    analysisOptInDate: string;
}

export interface Customer extends CustomerBase {
    password: string;
    cardNumber: string;
}

export interface ShortenedRegistrationCustomer extends BaseType {
    email: string;
    birthDay: string;
    zipCode: string;
    password: string;
    cardNumber: string;
}

export interface Term extends BaseType {
    isAccepted: boolean;
    version: number;
    category: number;
    date: string;
}

export interface NewsLetter extends BaseType {
    isAccepted: boolean;
    category: number;
    date: number;
}

export interface TokenResponse {
    accessToken: string;
    expiresIn: number;
    idToken: string;
    refreshToken: string;
    scope: string;
    tokenType: string;
}

export interface StoreOpeningHours extends BaseType {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
}

export interface User {
    address: string;
    birtdate: string;
    family_name: string;
    gender: string;
    given_name: string;
    tegut_identifier: string;
    unique_name: string;
}

export interface Address extends BaseType {
    street: string;
    city: string;
    zipCode: string;
}

export interface MapMarker extends BaseType {
    name: string;
}

export interface MinutesOpen extends BaseType {
    value: number;
}

export interface TegutStore extends MapMarker {
    tegutStoreIdentifier: string;
    store: number;
}

export interface ContactForm extends BaseType {
    message: string;
    subject: string;
    phoneNumber?: string;
    typeOfContact: string;
    tegutStore?: string;
}

export interface ArticleCategory extends BaseType {
    categoryName: string;
    tegutCategoryId: string;
    parentCategoryId?: string;
    childCategories?: ArticleCategory[];
}

export interface KeyValuePair {
    key: string;
    value: string;
}

export interface KeyValueArrayPair {
    key: string;
    value: string[];
}

export interface Article extends BaseType {
    tan: string;
    name: string;
    description: string;
    imagePath: string;
    expirationDate?: string;
    tags?: KeyValuePair[];
}

export enum ArticleSearchSortingOrder {
    ASC = 0,
    DESC = 1,
}

export interface ActivePromotionList extends BaseType {
    promotions: Promotion[];
}

export interface Promotion extends BaseType {
    tegutPromotionIdentifier: string;
    promotionTitle: string;
    productDescription: string;
    footnotes: string[];
    image: string;
    startDate: string;
    endDate: string;
    isActivated: boolean;
    advantage: PromotionAdvantage;
    advantageAmount: number;
    advantageDescription: string;
    brand?: string;
    variety?: string;
    packageContents?: string;
}

export enum PromotionAdvantage {
    Multiplier = 0,
    PlusPoints = 1,
}

export type ErrorMessageType = "timeOut" | "default" | "critical";

export interface AxiosRequestError {
    message: string;
    data: {
        statusCode: number;
        message: string;
    };
    status: number;
}

export enum ErrorStatusCode {
    internalServerError = 500,
    badRequest = 400,
    noError = -1,
    timeOut = 0,
}

export interface News extends BaseType {
    id: number;
    startDate: string;
    endDate: string;
    imagePath: string;
    imagePathWeb: string;
    newsUrl: string;
    body: string;
    newUntilDate: string;
    position: number;
}

export interface FaqList {
    key: string;
    value: Faq[];
}

export interface Faq {
    id: number;
    title: string;
    body: string;
    tags: string;
}

export interface FaqRequest {
    filter: string;
}

export interface CustomerScoreTransaction extends BaseType {
    id: string;
    receiptDateUtc: Date;
    transactionDateUtc: Date;
    store: string;
    totalPrice: number;
    totalDiscountablePrice: number;
    totalPointsSpent: number;
    totalPointsEarned: number;
    basePointsEarned: number;
    multiplierPointsEarned: number;
    promotionPointsEarned: number;
    totalPointsBeforeTransaction: number;
    totalPointsAfterTransaction: number;
    incentiveDetails: Incentive[];
    bonusPoints: number;
    reason: string;
}

export interface Incentive {
    name: string;
    points: number;
    incentiveEarnType: IncentiveType;
}

export enum IncentiveType {
    coupon = "Coupon",
    direct_benefit = "Sofortvorteil",
    earn_action = "Punkteaktion",
}

export interface LastModifiedTimestamps extends BaseType {
    multipliers: number;
    customer: number;
    customerScore: number;
    markets: number;
    promotions: number;
    news: number;
    contactCategories: number;
    documents: number;
    documents_tebonus: number;
    categories: number;
    customerPromotionSubscriptions?: number;
}

export interface Documents extends BaseType {
    name: string;
    data: string;
}

export enum CardType {
    unknown = "UnknownCard",
    starterPackage = "StarterPackageCard",
    physical = "PhysicalCard",
    digital = "DigitalCard",
}

export enum CardState {
    unknown = "unknown",
    active = "active",
    ordered = "ordered",
    locked = "locked",
}

export interface CardOverviewInfo extends BaseType {
    cardNumber: string;
    cardType: CardType;
    isLockedForEarn: boolean;
    isLockedForSpend: boolean;
    state: CardState;
}

export interface CardCodeRequest extends BaseType {
    width: number;
    height: number;
}

export interface CardCode extends BaseType {
    codeImage: string;
}

export interface Location extends BaseType {
    zipCodeInformationId: number;
    code: string;
    locationName: string;
    stateName: string;
}

export interface SendCardLockRequest {
    cardNumber: string;
    orderNewCard?: boolean;
}

export enum ServerEnvironmentType {
    dev = "Development",
    prod = "Produktiv",
    test = "Test",
}

export interface FooterElement {
    title: React.JSX.Element;
    htmlLink: string;
}

export interface AccessTokenData {
    /** Access token expiration timestamp UTC  */
    exp: string;
    unique_name: string;
    tegut_identifier: string;
    tebonus_terminated: boolean;
}

export interface WindowEnv extends Window {
    env: object;
}

/*********** Firebase Analytics ***********/

export interface SelectContentParameters {
    content_type?: string;
    item_id?: string;
    [key: string]: unknown;
}

export interface SearchParameters {
    [key: string]: unknown;
    search_term?: string;
}

export interface SliderSwipeEventParameters {
    slider_direction: SliderDirection;
    KachelPosition: number;
}

export enum SliderDirection {
    next = "vor",
    previous = "zurueck",
}
