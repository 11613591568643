import PageBase from "components/Shared/PageBase";
import { actionCreators } from "store";
import { CustomerState } from "store/Customers";
import { AppState } from "store/configureStore";
import { CommonButtonTitles, logAnalyticsButton, setAnalyticsConsent } from "utils/FirebaseAnalytics";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import React, { FunctionComponent, ReactElement } from "react";
import Switch from "react-switch";

const PrivacySettings: FunctionComponent = (): ReactElement => {
    const BUTTON_STATE_YES = "Ja";
    const CONSENT_ACCEPTED_SWITCH_TEXT = "Einwilligung erteilt";
    const CONSENT_DECLINED_SWITCH_TEXT = "Einwilligung nicht erteilt";
    const customerState: CustomerState = useAppSelector((state: AppState) => state.customerReducer);

    const dispatch = useAppDispatch();

    function getTrackingConsentSwitchText(): string {
        return customerState.isTrackingConsentAccepted ? CONSENT_ACCEPTED_SWITCH_TEXT : CONSENT_DECLINED_SWITCH_TEXT;
    }

    function setTrackingConsentState(checked: boolean): void {
        dispatch(actionCreators.customers.setTrackingConsentState(checked));
        setAnalyticsConsent(checked);

        if (checked) {
            logAnalyticsButton({
                button_title: CommonButtonTitles.consent.privacyConsent,
                button_state: BUTTON_STATE_YES,
            });
        }
    }

    return (
        <PageBase title="Privatsphäre-Einstellungen">
            <div className="mx-4">
                <p>
                    Wir verwenden eine anonyme Nutzeranalyse um zu messen, wie unser Web-Angebot genutzt wird. Wir erfassen im
                    Rahmen der anonymen Nutzeranalyse den Aufruf von Web-Seiten (ScreenViews) sowie den Aufruf von Funktionen
                    (Events) wie z.B. das Speichern von Lieblingsprodukten.
                </p>
                <p>
                    Wir nutzen diese Daten ausschließlich zur Optimierung unseres Angebotes. Eine Nutzung Ihrer Daten zu
                    Werbezwecken erfolgt nicht.
                </p>
            </div>
            <div className="mx-4 mt-4 d-flex">
                <Switch
                    onChange={checked => setTrackingConsentState(checked)}
                    checked={customerState.isTrackingConsentAccepted}
                />
                <label className="pl-2">{getTrackingConsentSwitchText()}</label>
            </div>
        </PageBase>
    );
};

export default PrivacySettings;
